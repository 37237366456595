<template>
  <div class="bbr-mindset--details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Mindset Details</h2>

          <v-btn
            v-if="mindset.id"
            class="primary--text mr-5"
            @click="$router.go(-1)"
            text
          >
            Back
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :loading="form.$busy"
            @click="saveDetails"
            depressed
            text
          >
            <span> Save Mindset </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <v-slide-y-transition>
          <div v-if="!loading">
            <mindset-details-form :form-data="form" />
          </div>
        </v-slide-y-transition>

        <v-slide-y-transition>
          <v-skeleton-loader v-if="loading" class="mb-6" :type="loader" />
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MindsetDetailsForm from '../components/MindsetDetailsForm'
import Form from '@/utils/form'

import { mapActions, mapMutations, mapGetters } from 'vuex'
import { mdiCheck } from '@mdi/js'
import { extend } from 'lodash'

export default {
  name: 'MindsetDetails',

  components: {
    MindsetDetailsForm,
  },

  data() {
    return {
      loading: false,
      loader:
        'actions, list-item-two-line, list-item-two-line, list-item-two-line',
      transition: 'scale-transition',
      icons: {
        check: mdiCheck,
      },
      form: new Form({
        day: null,
        week: null,
        title: null,
        program: null,
        description: null,
        id: this.$attrs.id,
      }),
    }
  },

  computed: {
    ...mapGetters({
      mindset: 'mindsets/getSelecteMindset',
    }),
  },

  created() {
    if (this.$attrs.id) {
      this._getMindset()
    }
  },

  methods: {
    ...mapActions({
      getMindset: 'mindsets/getMindset',
      saveMindset: 'mindsets/saveMindset',
    }),

    ...mapMutations({
      setSelectedMindset: 'mindsets/setSelectedMindset',
      updateOrInsertMindset: 'mindsets/updateOrInsertMindset',
    }),

    async _getMindset() {
      if (this.loading) return

      this.loading = true

      await this.getMindset(this.$attrs.id).then(({ data }) => {
        this.setSelectedMindset(data)
      })

      await this.$sleep()

      this.loading = false
    },

    async saveDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (this.form.program) {
        this.form.program_id = this.form.program.id
      }

      await this.saveMindset(this.form)
        .then(({ data }) => {
          this.updateOrInsertMindset(data)

          this.form.$timeout(() => {
            this.$router.push({ name: 'mindsets' })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },

  watch: {
    mindset(values) {
      this.form = extend(this.form, values)
    },
  },
}
</script>
