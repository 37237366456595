<template>
  <v-row class="bbr-mindset--details-form">
    <v-col cols="12">
      <v-text-field
        v-model="form.title"
        label="Mindset Title"
        :error-messages="form.$getError('title')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="6">
      <video-programs
        v-model="form.program"
        label="Program Mindset"
        placeholder="Search for Programs and Challenge"
        :error-messages="form.$getError('program_id')"
        return-object
        clearable
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-select
        v-model="form.week"
        :items="weeks"
        :readonly="!form.program"
        :error-messages="form.$getError('week')"
        :hide-details="!form.$getError('week')"
        label="Mindset Week"
        item-value="id"
        item-text="name"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-select
        v-model="form.day"
        :items="days"
        :readonly="!form.program"
        :error-messages="form.$getError('day')"
        label="Mindset Day"
        item-text="name"
        item-value="id"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.description"
        label="Mindset Description"
        :error-messages="form.$getError('description')"
        auto-grow
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import VideoPrograms from '@/components/elements/programs/SearchProgramsInput'
import Form from '@/utils/form'

import { mask } from 'vue-the-mask'
import { map, isEmpty } from 'lodash'

export default {
  name: 'MindsetDetailsForm',

  components: {
    VideoPrograms,
  },

  directives: {
    mask,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      form: this.formData,

      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],
    }
  },

  computed: {
    weeks() {
      if (isEmpty(this.form.program)) return []

      let weeks =
        this.form.program.week_count + this.form.program.deload_week_count

      return map([...Array(weeks).keys()], (week) => {
        let _week = week + 1

        return {
          id: _week,
          name: _week,
        }
      })
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    'form.program': {
      handler(value) {
        if (isEmpty(value)) {
          this.form.day = null
          this.form.week = null
        }
      },
      deep: true,
    },
  },
}
</script>
